.external-links-wrapper {
  .save-cancel-wrapper {
    display: none;
  }
  .repeated-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    @include rtl {
      justify-content: flex-start;
    }
  }
}
