.NewReleaseNotification {
  justify-content: center;
  align-items: center;
  .img-container-NewReleaseNotification {
    width: 12rem;
    @include rtl {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
  .main-title {
    font-weight: 900;
    font-size: $fz-21px;
    color: #007ff5;
    padding: 1rem 0rem 1.3rem 0rem;
  }
}

.read-more-title {
  color: #c96b28;
  cursor: pointer;
  font-weight: 900;
  text-decoration: underline;
}

.NewReleaseNotification-wrapperClasses {
  flex: 1 1 auto;
  padding: 8px 0px 8px 21px !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include rtl {
    padding: 8px 21px 8px 0px !important;
  }
}
