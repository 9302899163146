.sliderUser {
  .item-body {
    @include d-inline-flex-center;
    margin-bottom: 0rem !important;
    padding: 0 0.25rem;
    flex-wrap: wrap;
    &.new-line {
      @include d-flex;
      padding: 0 0.5rem;
    }
  }
  .item-wrapper {
    color: #121212;
    font-weight: 600;
  }
  .cards-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    cursor: default;
    border-radius: 0rem;
    border: 0px solid #ffffff !important;
    color: #121212;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    -ms-border-radius: 0rem;
    -o-border-radius: 0rem;
  }

  .CardContenater {
    padding-left: 15px;
    padding-right: 15px;
  }

  .Cardgrid {
    text-align: center;
    width: 100%;
    height: 90px;
    background-color: #f0f0f0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .icon {
    color: #0C111D;
  }
  .textcard {
    color: #0C111D;
    font-size: 12px;
    display: flex;
    gap: 2.5rem;
    textarea {
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
    }
  }

  .name-wrapper {
    display: unset !important;
  }

  .textcardsmoll {
    color: #58acc1;
    font-weight: 500;
    font-size: 13px;
  }

  .iconbig {
    font-size: 35px;
    color: $c-white;
    margin-left: -22px;
    @include rtl {
      margin-left: 0px;
    }
  }
  .copyId {
    display: flex;
    gap: 0.7rem;
  }

  .textcardbig {
    color: $c-white;
    font-size: 15px;
    padding-top: 4px;
  }
  .textcardsmollbig {
    text-align: left !important;
    padding-top: 10px;
    padding-left: 28px;
    color: $c-white;
    font-size: 13px;
    padding-bottom: 10px;
  }

  .textcardsmollbig2 {
    color: $c-white;
    font-size: 13px;
  }

  .Contenater-icon {
    margin-top: 10px !important;
  }

  .state-online {
    background: rgb(66, 183, 42);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
    @include rtl {
      margin-right: 10px;
    }
  }
  .state-offline {
    background: rgb(201, 37, 15);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
  }
}
.d-flex-column {
  display: flex;
}
textarea {
  // width: 0;
  // height: 0;
  // padding: 0;
  // opacity: 0;
}

//textarea-BUG
