.error-fallback-ui-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(247, 249, 251);
    height: 100vh;

    h1,
    p {
        width: fit-content;
        text-align: center;
        width: 80%;
    }

    .fallback-container {

        &>* {
            margin-top: 1vw;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        display: flex;
        flex-direction: column;

        img {
            display: block;
            width: 18vw;
        }

        h1 {
            color: rgb(87, 118, 179);
            font-size: 1.8vw;
        }

        p {
            color: rgb(122, 122, 122);
            font-size: .9vw;
        }
        button {
            background-color: rgb(87, 118, 191);
            border-radius: 6px;
            display: flex;
            align-items: center;
            border-width: 0px;
            box-shadow: rgba(50, 50, 93, 0.1) 0px 0px 0px 1px inset, rgba(50, 50, 93, 0.1) 0px 2px 5px 0px, rgba(0, 0, 0, 0.07) 0px 1px 1px 0px;
            color: rgb(241, 241, 241);
            cursor: pointer;            
            font-size: .7vw;
            height: 2.1vw;
            line-height: 1.15;
            outline: none;
            padding: 0px 20px;
            width: fit-content;
        }
    }

}
