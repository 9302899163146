.i-Aannotation-question {
  background-image: $i-Aannotation-question;
}

.i-bell {
  background-image: $i-bell;
}

.i-calendar {
  background-image: $i-calendar;
}

.i-file {
  background-image: $i-file;
}

.i-currency-dollar-circle {
  background-image: $i-currency-dollar-circle;
}

.i-home {
  background-image: $i-home;
}

.i-home-line {
  background-image: $i-home-line;
}

.i-image {
  background-image: $i-image;
}

.i-key {
  background-image: $i-key;
}

.i-list {
  background-image: $i-list;
}

.i-marker-pin {
  background-image: $i-marker-pin;
}

.i-Percentage-discount {
  background-image: $i-Percentage-discount;
}

.i-Property-owner {
  background-image: $i-Property-owner;
}

.i-Rent-home {
  background-image: $i-Rent-home;
}

.i-Sale-home {
  background-image: $i-Sale-home;
}

.i-settings {
  background-image: $i-settings;
}

.i-settings-Cogwheel {
  background-image: $i-settings-Cogwheel;
}

.i-trumpet {
  background-image: $i-trumpet;
}

.i-user {
  background-image: $i-user;
}

.i-users-edit {
  background-image: $i-users-edit;
}

.i-reverse-right {
  background-image: $i-reverse-right;
}

.i-switch-horizontal {
  background-image: $i-switch-horizontal;
}

.i-help-circle {
  background-image: $i-help-circle;
}

.i-clock {
  background-image: $i-clock;
}

.i-unit-size {
  background-image: $i-unit-size;
}

.i-copy-03 {
  background-image: $i-copy-03;
}

.i-bed {
  background-image: $i-bed;
}
.i-parking {
  background-image: $i-parking;
}
.i-x-close-dialog {
  background-image: $i-x-close-dialog;
}
.i-bath {
  background-image: $i-bath;
}

.i-settings-04 {
  background-image: $i-settings-04;
}

.i-check {
  background-image: $i-check;
}

.i-plus {
  background-image: $i-plus;
}

.i-x-close {
  background-image: $i-x-close;
}

.i-search-lg {
  background-image: $i-search-lg;
}

.i-user-chevron-right {
  background-image: $i-user-chevron-right;
}

.i-user-square {
  background-image: $i-user-square;
}

.i-users-voice {
  background-image: $i-users-voice;
}
