$bs-primary: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
$bs-secondary: 2px 4px 4px 0 rgba(136, 145, 145, 0.75);

// app reskinning box shadows
$bs-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$bs-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
$bs-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
$bs-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
$bs-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
$bs-2xl: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
$bs-3xl: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);


// app reskinning focus rings levels
$fr-brand: 0px 0px 0px 4px rgba(140, 115, 75, 0.24);
$fr-gray: 0px 0px 0px 4px rgba(152, 162, 179, 0.14);
$fr-gray-secondary: 0px 0px 0px 4px rgba(152, 162, 179, 0.20);
$fr-error: 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
$fr-brand-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24);
$fr-brand-shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
$fr-gray-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14);
$fr-gray-shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(152, 162, 179, 0.14);
$fr-error-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);