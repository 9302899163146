.dialog-content-item {
  border-top: none;
  margin-top: 10px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @include phones-max {
    width: 100%;
    .rotationCriteriaDialog-dialog-wrapper
      .dialog-content-wrapper
      .dialog-content-item {
      padding: 15px;
    }
  }
  padding: 0.5rem;
  .switch-status {
    align-self: flex-end;
  }
}

.assign-recruiters-dialog-wrapper {
  padding: 2rem;

  .dialog-content-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
