// .checkbox-groups-wrapper.MuiFormControl-root {
//   padding: 0 0.5rem 0 1.5rem;

//   @include rtl {
//     padding: 0 1.5rem 0 0.5rem;
//   }

//   &::before {
//     content: ' ';
//     border: 0.25rem solid $c-gray-secondary;
//     position: absolute;
//     left: 0.25rem;
//     top: 0;
//     height: 100%;

//     @include rtl {
//       right: 0.25rem;
//       left: initial;
//     }
//   }
// }

// .checkbox-wrapper {
//   .MuiSvgIcon-root {
//     path {
//       fill: $c-primary;
//     }
//   }

//   &.Mui-checked,
//   &.MuiCheckbox-indeterminate {
//     .mdi {
//       color: $c-white;
//       background-color: $c-primary;
//       height: 18px;
//       width: 18px;
//       border-radius: 0.15rem;
//       line-height: 15px;
//       &::before {
//         font-size: 17px;
//       }
//     }
//   }

//   &.MuiIconButton-root {
//     border-radius: 0.15rem;
//     height: 2rem;
//     width: 2rem;
//   }

//   &.theme-secondary {
//     .MuiSvgIcon-root {
//       path {
//         fill: $c-secondary;
//       }
//     }

//     &.Mui-checked,
//     &.MuiCheckbox-indeterminate {
//       .mdi {
//         background-color: $c-secondary;
//       }
//     }
//   }
// }
.checkbox-groups-wrapper {
  .PrivateSwitchBase-input-19 {
    margin-top: 0;
  }
  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
  .PrivateSwitchBase-input-4 {
    margin-top: 0;
  }
  .PrivateSwitchBase-input-8 {
    margin-top: 0;
  }
  .checkbox-wrapper {
    .MuiSvgIcon-root {
      path {
        fill: $c-primary;
      }
    }
    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      .mdi {
        color: $c-white;
        background-color: $c-primary;
      }
    }
    .mdi {
      height: 18px;
      width: 18px;
      border-radius: 0.3rem;
      line-height: 13px;
      &::before {
        font-size: 17px;
      }
    }
    .i-unchecked {
      height: 18px;
      width: 18px;
      border: 2px solid $c-primary;
      border-radius: 0.25rem;
    }
    .PrivateSwitchBase-root-5 {
      padding: 0.5rem;
    }

    &.MuiIconButton-root {
      border-radius: 0.3rem;
    }
  }
  &.theme-default {
    .checkbox-wrapper {
      .MuiSvgIcon-root {
        path {
          fill: $c-primary;
        }
      }
      .i-unchecked {
        border-color: $c-gray-dark;
      }
      &.Mui-checked,
      &.MuiCheckbox-indeterminate {
        .mdi {
          background-color: $c-primary;
        }
      }
    }
  }
  &.theme-secondary {
    .checkbox-wrapper {
      .MuiSvgIcon-root {
        path {
          fill: $c-primary;
        }
      }
      &.Mui-checked,
      &.MuiCheckbox-indeterminate {
        .mdi {
          background-color: $c-primary;
        }
      }
    }
    }
  
    &.theme-propx {
      .checkbox-wrapper {
     
        .MuiSvgIcon-root {
          path {
            fill: $c-primary;
          }
        }
        .i-unchecked {
        border: 1px solid #D0D5DD !important;
        width: 18px !important;
        height: 18px!important;
      }
        &.Mui-checked,
        &.MuiCheckbox-indeterminate {
          .mdi {
            border-radius: .25rem;
            background-color: #7F6944 !important;          }
        }

        &:hover {
          background-color: unset !important;
      }
      }

      &.rounded .checkbox-wrapper {
        &.Mui-checked,
        &.MuiCheckbox-indeterminate {
          .mdi{
            border-radius: 50%;
          }
          .mdi::before {
            font-size: 12px;
            display: block;
            margin-top: 3px;
          }
        }
      }

      .i-unchecked { 
            border-radius: 50%;
      }

    }

  &.theme-dark {
    .MuiFormControlLabel-label {
      color: $c-white;
    }
  }
  .form-control-label.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
  }
}
