$spinner-bg-color: rgba(0, 0, 0, 0.08);
$text-color: #595959;
$loading-opacity-duration: 2s;
$fade-in-out: 0.3s;

.spinner-wrapper {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: $spinner-bg-color;
  color: white;
  transform: translate(-50%, -50%);
  transition: opacity $fade-in-out;
  opacity: 1;

  &.is-absolute {
    position: absolute;
  }

  &.is-small .spinner-img {
    max-width: 32px;
  }

  .app-spinner {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #989898;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    span {
      color: $text-color;
      animation: loading-opacity $loading-opacity-duration linear infinite;
    }
  }
}

@keyframes loading-opacity {
  0%,
  20%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
