.teams-part {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .user-management-team {
    border: 1px solid #e6e6e6;
    border-radius: 1rem;
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;

    .manager-Crown {
    height: 40px !important;
     width: 40px !important; 
     margin: 0 auto !important; 
    display: block;
    // background-position: center;
    /* min-width: 100px; */
    /* min-height: 100px; */
    background-repeat: no-repeat;
    }

    // .startPart {
    //   //width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    .firstPart {
      margin: 0.5rem;
      width: 100%;
      // display: grid;
      // grid-template-rows: repeat(1, auto);
      // grid-template-columns: repeat(2, auto);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .headerName {
      font-weight: bold;
    }

    .secondPart {
      text-align: center;
      justify-content: center;
    }

    .star-color {
      color: rgb(218, 218, 0);
    }
  }
}
