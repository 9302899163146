@import "../../../../../assets/theme-style/Helpers/Helpers.Style.scss";
.userLoginTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-management-view {
  .body-content {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    .container {
      display: flex;
      flex-direction: column;
      width: 50%;

      .team {
        border-radius: 1rem;
        padding: 0.5rem 0.5rem 1rem;
        border: 1px solid #e6e6e6;
        width: 100%;
        margin-top: 18px;
        .manager-Crown {
          height: 40px;
          width: 40px;
          margin: 0 10px;
        }
        .team-header {
          display: flex;
          justify-content: flex-start;
          padding: 0.5rem;
          font-weight: 800px;
          font-size: 1.25rem;
        }
        .team-scroll {
          overflow-y: auto;
          padding-right: 0.5rem;

          .team-section {
            width: 100%;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .information-section {
      @include d-inline-flex;
      width: 100%;
      @include laptops-max {
        width: 100%;
      }
      margin-bottom: 1rem;
      padding: 0 0.5rem;
      color: $c-black;
      .information-box-wrapper {
        @include d-flex-column;
        border: 1px solid #e6e6e6;
        border-radius: 1rem;
      }
      .information-section-content {
        @include d-flex;
        padding: 1rem 0.5rem;
        width: 100%;
        @include phones-s-max {
          flex-direction: column;
        }
        .image-wrapper {
          padding: 0.5rem;
        }
        .roles-wrapper {
          @include d-flex-v-center;
          @include phones-s-max {
            flex-direction: column;
          }
          .roles-content {
            color: $c-black;
            @include d-inline-flex-v-center;
            flex-wrap: wrap;
            margin-bottom: 0.25rem;

            span {
              margin-bottom: 0.25rem;
            }
          }
          .roles-button {
            margin: 0 0.5rem 0.5rem;
            border-radius: 0.5rem;
            border-color: $c-black;
            justify-content: space-between;
          }
        }
        .information-content-wrapper {
          @include d-flex-column;
          width: 100%;
          padding: 0.5rem;
          .adress-row {
            align-items: flex-start;
          }
          .about-me {
            margin: 2% 0;
            .MuiInputBase-input {
              height: 100px;
              border: -0.5px solid #e6e6e6;
            }
          }
        }
      }
      .footer-content-wrapper {
        @include d-flex-v-center-h-between;
        flex: wrap;
        width: 100%;
        padding: 1rem 0.5rem 0;
        @include phones-s-max {
        }
        .footer-section {
          @include d-inline-flex-column;
          padding: 0 0.5rem;
          margin-bottom: 0.5rem;
          font-weight: bold;
          flex: 1 1 auto;
          .section-item {
            @include d-flex-v-center;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .form-item {
      width: 100%;
      margin-bottom: 1rem;
    }
    .form-range-wrapper {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .item-range {
        width: 49%;
      }
    }
    .account-dialog-section {
      .phones-wrapper .flag-dropdown .country-list {
        position: absolute !important;
      }
      @include d-inline-flex-column;
      width: 40%;
      @include laptops-max {
        width: 100%;
      }
      padding: 0 0.5rem;
      margin-top: 0.5rem;
    }
    .dialog-header {
      @include d-flex-center;
      background-color: var(--c-primary);
      border-radius: 1rem 1rem 0 0;
      padding: 1rem;
      color: white;
      font-size: $fz-16px;
    }
    .dialog-body {
      @include d-flex-column;
      border: 1px solid #e6e6e6;
      border-radius: 1rem;
      padding: 1rem 1rem 0;
      .adress-row {
        align-items: flex-start;
      }
    }
  }
  .Butt-wrpaer-password {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
  }

  .fullName {
    display: flex;
    justify-content: space-between;
    .fullName-wrapper {
      width: 70%;
    }
    .userId {
      display: flex;
      align-items: flex-start;
    }

    .endPart {
      display: inline-flex;
    }
  }
}
