.ReportsBuilderView-css {
  .Rep {
    .date-range-picker-component {
      height: 2rem !important;
    }
  }
  .sc-search {
    .min-30 {
      width: 30px;
    }
    justify-content: center;
    align-items: flex-start;
  }
}

.ReportsBuilder-data-view-wrapers {
  padding-right: 1rem;
  padding-left: 1rem;
}

.FilterSectionView-view-wrapers {
  overflow: auto;
  max-height: 26rem;
}

.ReportsBuilder-view-wrapers {
  min-height: calc(100vh - 205px);
  height: 85vh;
  width: 100%;
  flex-wrap: wrap;
  display: inline-flex;

  @include laptops-max {
    justify-content: center;
  }

  .Form-view {
    background-color: #f7f7f7;
    width: 80%;
    padding: 12px;

    @include laptops-max {
      width: 100%;
    }

    .cancel-btns {
      background-color: #8e8e939c;
      color: white;
    }

    .cancel-btns:hover {
      background-color: #c52d229c;
      color: white;
    }

    .run-btns {
      background-color: #15a20af7;
      color: $c-white;
    }
  }

  .internal-Form-view {
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px -1px #888888;
    width: auto;
    background-color: $c-white;

    @keyframes blinkdot {
      0% {
        opacity: 0.2;
        color: #1b75e3;
      }

      20% {
        opacity: 1;
        color: #004492;
      }

      100% {
        opacity: 0.2;
        color: #210dff;
      }
    }

    .loading span {
      color: #1b75e3;
      animation-name: blinkdot;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }

    .loading span:nth-child(2) {
      animation-delay: 0.2s;
    }

    .loading span:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  .SettingsSectionView-view-wrapers {
    .datePickerReprt {
      .date-range-picker-component .display-wrapper {
        background-color: $search-box-bg-color !important;
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.25rem 0.5rem;
        min-width: 100px;
        border-radius: 0rem;
        border: aliceblue !important;
      }

      .date-range-picker-component {
        min-width: 379px;
      }

      display: flex;
      border: 1px solid #d2c4c4;
      border-radius: 8px;
      padding: 3px;
      margin: 8px;

      .date-range-picker-component .display-wrapper {
        border-left: 1px solid #c7c7cc !important;
      }
    }

    justify-content: space-between;
    display: flex;

    .title {
      font-size: 19px;
      font-weight: 900;
    }

    .Settings-bbt {
      .btns.MuiButtonBase-root {
        color: #114fe0 !important;
        font-weight: 600;
        border-radius: 5px;
      }
    }
  }

  .FilterSectionView-view-wrapers {

    background-color: #f8f9fa;
    height: 100px;
    padding: 10px;
    overflow: hidden;

    .Filter-title {
      margin-top: 12px;
      font-size: 1rem;
    }
  }
}

.header-section-FilterSectionView {
  .filter-section-AgentRotation {
    display: inline-flex;
    @include laptops-l-max {
      flex-wrap: wrap;
      justify-content:space-evenly;
    }
    grid-gap: 20px;
    gap: 20px;
  }
  .opation-tool {
    .MuiFab-sizeSmall {
      width: 27px;
      height: 25px;
    }

    .MuiFab-root {
      min-width: 0;
      min-height: 0px;
    }

    .MuiFab-label {
      color: #ff004799;
    }

    &.add {
      .MuiFab-label {
        color: #0eca5399;
      }
    }
  }

  display: flex;
  margin-top: 1rem;

  .date-range-picker-component .display-wrapper {
    background-color: white;
  }

  .SMALL {
    max-width: 16rem !important;
  }

  .select-55 {
    min-width: 100;
    width: 160px;
    .autocomplete-wrapper {
      min-width: 110px;
    }
  }

  .datePickerComponentAge {
    width: 16rem;
  }
}

.TreeFieldsView-container {

  width: 20%;
  &.templet-w
  {
    width:30%;
    width: fit-content;
  }
  .TreeFieldsView-container .MuiFab-sizeSmall {
    width: 27px;
    height: 29px;
  }

  .MuiFab-root {
    color: #3f3fe4de;
    padding: 0;
    font-size: 0.875rem;
    width: 27px;
    height: 29px;
    min-width: 0;
    box-sizing: border-box;
    min-height: 3px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    border-radius: 27%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: var(--c-primary);
  }



  @include laptops-max {
    width: 60%;
  }

  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-top: 6px solid #f7f7f7;

  .main-title {
    font-size: 16px;
    font-weight: 600;
  }

  .title-h {
    border-bottom: 1px solid #dedede;
  }

  .TreeFieldsView {
    .pointercursor {
      cursor: pointer;
    }

    @include laptops-max {
      height: 30rem;
    }

    height: 50rem;
    overflow: auto;
    margin-left: 2rem;
    margin-top: 1rem;

    &.istemplet
    {
      height: 30rem;
      width: 16rem;
    }
    .tree,
    .tree ul {
      margin: 0 0 0 1em;

      /* indentation */
      @include rtl {
        margin: 0rem 1rem 0 1em;
      }

      padding: 0;
      list-style: none;
      color: #369;
      position: relative;

      .form-name {
        color: #3b72d9;
        text-transform: capitalize;
        cursor: pointer;
        &.form-have-data
        {
          color: rgb(9, 217, 89);
        }
        &.form-not-have-data
        {
          color: #8f72724f;
          cursor: not-allowed;
        }
      }
    }

    .tree .item-in {
      .no-data {
        color: black;
        font-size: 10px;
        padding: 2px;
        margin: 7px;
        border: 1px dashed #00000b;
        border-radius: 7px;
        background-color: #3b72d921;
      }

      margin-left: 0.5em;
      display: none;

      &.is-open {
        display: block;
      }
    }

    /* (indentation/2) */

    .tree:before,
    .tree ul:before {
      content: '';
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid;

      @include rtl {
        right: 0;
        left: none;
      }
    }

    .tree li {
      margin: 0;
      padding: 0 1.5em;
      /* indentation + .5em */
      line-height: 2em;
      /* default list item's `line-height` */
      font-weight: bold;
      position: relative;

      .formFieldKey {
        cursor: move;
        font-weight: bold;
        text-transform: capitalize;
        margin-left: 10px;

        @include rtl {
          margin-right: 10px;
        }

        &.displaydrage
        {
          text-decoration: line-through;
          color: #959595;
          text-decoration-color: #f10707;
          cursor: not-allowed;
 
        }
      }

      .formFieldKey:hover {
        color: #2198cf;
        font-weight: bolder;
        &.displaydrage
        {
          text-decoration: line-through;
          color: #959595;
          text-decoration-color: #f10707;
          cursor: not-allowed;
 
        }
        
      }
    }

    .tree li:before {
      content: '';
      display: block;
      width: 20px;
      /* same with indentation */
      height: 0;
      border-top: 1px solid;
      // margin-top: -4px; /* border top width */
      position: absolute;
      top: 1em;
      /* (line-height/2) */
      left: 0;

      @include rtl {
        right: 0;
        left: none;
        border-top: 1px solid;
        margin-top: -1px;
      }
    }

    .tree li:last-child:before {
      background: white;
      height: auto;
      top: 1em;
      bottom: 0;
    }
  }
}

.TableReportsView-view-wrapers {
  .input-selected
  {
    .input-wrapper.theme-default .text-field-wrapper .inputs .MuiInputBase-root {
      background-color: #09171d52;
      border: 1px dotted #f0f0f0;
  }
  }
  .text-field-wrapper {
    margin-top: 7px;
  }
  .fixed_headers {
    border-collapse: collapse;
    width: 100%;
  }
  .fixed_headers td,
  .fixed_headers thead th {
    padding: 5px;
    text-align: left;
  }

  .fixed_headers thead th {     min-width: 15rem; }
  .TotalCount-title {
    color: $c-primary;
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
  }

  table {
    border-collapse: collapse;
  }

  td,
  th {
    padding: 5px 15px;
    text-align: left;
  }

  table,
  th,
  td {
    border: 1px solid #000;
  }
  min-height: 28rem;
  position: relative;
  overflow: auto;
  .table {
    border-collapse: collapse;
    font-weight: 900;
  }

  .table,
  .table th,
  .table td {
    border: 1px solid #ccc;
    .fixed_headers {
      border-collapse: collapse;
      width: 100%;
    }

    // .fixed_headers thead th {
    //   position: sticky;
    //   resize: horizontal;
    //   overflow: auto;
    //   min-width: 10px;
    // }
    th,
    td {
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    th {
      background: repeating-linear-gradient(
        28deg,
        var(--c-primary)05,
        #d7e0f3c9 2px,
        #ffffff 2px,
        #dbdbfa40 10px
      );
      color: white;
    }
  }

  .table th,
  .table td {
    padding: 0.5rem;
    height: 26px;
    text-transform: capitalize;
  }

  .table th {
    position: relative;
  }



  .resizable {
    border: 1px solid gray;
    height: 100px;
    width: 100px;
    position: relative;
  }

  .trash-bbt {
    min-width: 20px !important;
    border: 1px solid #6e6a6a45;

    .MuiButton-text {
      padding: 1px 8px;
    }

    .MuiButton-root {
      min-width: 20px !important;
    }

    .mdi {
      color: $c-red !important;
      min-width: 20px !important;

      &.is-red {
        color: #08f90d !important;
        min-width: 20px !important;
      }
    }

    &.is-red {
      .mdi {
        color: #000000 !important;
        min-width: 20px !important;
      }
    }

    &.is-selected {
      .mdi {
        color: #09b923 !important;
        min-width: 20px !important;
      }
    }
    &.is-selectedcheck {
      background-color: white;
      .mdi {
        color: #09b923 !important;
        min-width: 20px !important;
      }
    }
    &.is-rename {
      .mdi {
        color: #82829a !important;
        min-width: 20px !important;
      }
    }


  }
}

.malek {
  .cardmalek-contantr {
    padding: 0rem 2rem 2rem 1rem;

    .MuiSkeleton-pulse {
      height: 50px;
      min-width: 15rem;
      margin-top: 18px;
      display: flex;
      box-shadow: #0e1e251f 0px 2px 4px 0px, #0e1e2552 0px 2px 6px 0px;
      border-radius: 0.5rem;
    }

    .cardmalek-body {
      .con-text {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      // border: 1px solid #f9fafc;
      background: repeating-linear-gradient(
        225deg,
        var(--c-primary)00,
        #d7e0f382 2px,
        #ffffff 2px,
        #dbdbfa00 10px
      );
      height: 50px;
      min-width: 15rem;
      margin-top: 18px;
      display: flex;
      box-shadow: #0e1e251f 0px 2px 4px 0px, #0e1e2552 0px 2px 6px 0px;
      border-radius: 0.5rem;

      .left-side {
        // background-image: linear-gradient(to right, rgb(59, 114, 217), rgb(59, 114, 217) 2px, rgb(229, 229, 247) 2px, rgb(229, 229, 247));
        // background-size: 4px 100%;

        //background: radial-gradient(circle, transparent 20%, rgb(229, 229, 247) 20%, rgb(229, 229, 247) 80%, transparent 80%, transparent) 0% 0% / 20px 20px, radial-gradient(circle, transparent 20%, rgb(229, 229, 247) 20%, rgb(229, 229, 247) 80%, transparent 80%, transparent) 10px 10px / 20px 20px, linear-gradient(rgb(59, 114, 217) 0.8px, transparent 0.8px) 0px -0.4px / 10px 10px, linear-gradient(90deg, rgb(59, 114, 217) 0.8px, rgb(229, 229, 247) 0.8px) -0.4px 0px / 10px 10px;

        min-width: 3rem;
      }

      &.active {
        background-color: #899cd042;
      }
    }
  }

  @include d-flex-h-center;
  flex-wrap: wrap;
  width: 100%;

  .roles-form-content-wrapper {
    min-height: 372px;
    @include d-inline-flex-column;
    min-width: 270px;
    padding: 0 0.5rem;

    .role-name-wrapper {
      max-width: 500px;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
    }

    .roles-sections-wrapper {
      @include d-flex-h-center;
      width: 100%;
      align-items: flex-start;

      @include laptops-l-max {
        flex-wrap: wrap;
      }

      .roles-modules-wrapper {
        @include d-inline-flex-column;
        min-width: 301px;

        @include phones-max {
          min-width: 285px;
        }

        .roles-module-items-wrapper {
          .MuiSkeleton-pulse {
            width: 261px;
            height: 35px;
            margin: 0 0 0.5rem;
            border-radius: 0.5rem;
          }

          min-height: 280px;

         // justify-content: space-between;
          justify-content: flex-start ;


          @include d-flex-column;
          width: 100%;
          padding-top: 0.5rem;

          .roles-module-item {
            margin: 0 0 0.5rem;
            border-radius: 0.5rem;
            min-height: 35px;

            &:not(.active-module) {
              background-color: $c-gray-ef;
              color: $c-black;
            }

            &.active-module {
              position: relative;

              &::before {
                content: '\F0794';
                font-family: 'Material Design Icons';
                position: absolute;
                right: -2rem;
                color: $c-gray;
                font-size: $fz-22px;

                @include rtl {
                  left: -24rem;
                  transform: scaleX(-1);
                }
              }
            }
          }
        }
      }

      .roles-components-wrapper {
        @include d-inline-flex-column;
        padding: 0 0.5rem;
        max-width: 1200px;
        width: 100%;

        @include laptops-l-max {
          min-width: 285px;
        }

        .roles-component-items-wrapper {
          @include d-flex-column;
          width: 100%;
          margin-top: 0.5rem;

          .roles-component-item {
            @include d-flex-v-center;
            flex: 0 1 auto;
            width: 100%;
            margin-bottom: 0.5rem;
            border: 1px solid $c-gray;
            border-radius: 0.5rem;
            position: relative;
            margin-bottom: 0.5rem;

            @include tablets-max {
              min-width: 100%;
            }

            &.active-component {
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
              border-color: $c-primary;
            }

            .roles-permissions-wrapper {
              @include d-flex-v-center;
              flex-wrap: wrap;
              width: 100%;
              min-height: 40px;
              padding: 0.25rem 0.5rem;
              border-radius: 0.5rem;
              align-content: center;

              .roles-permission-item {
                @include d-inline-flex;
                padding: 0 0.5rem;
                min-width: 200px;

                // width: 20%;
                @include laptops-max {
                  width: auto;
                }
              }

              .roles-permission-items-wrapper {
                @include d-flex-v-center;
                flex-wrap: wrap;
                width: 100%;

                .title-text {
                  min-width: 115px;
                  // width: 20%;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include laptops-max {
                    width: auto;
                  }
                }
              }

              .expanding-btn {
                margin: 0;
                position: absolute;
                right: -0.75rem;

                @include rtl {
                  right: initial;
                  left: -0.75rem;
                }
              }
            }
          }
        }
      }

      .roles-access-types-wrapper {
        margin-top: 2.75rem;

        @include laptops-max {
          margin-top: 1rem;
        }

        padding: 0 0.5rem;

        .access-types-content-wrapper {
          @include d-inline-flex-column;
          min-width: 319px;

          @include phones-max {
            min-width: 285px;
          }

          box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.141);
          border-radius: 0.5rem;

          .access-types-header-wrapper {
            @include d-flex-v-center-h-between;
            padding: 0.5rem 0.5rem 0;
            margin-bottom: 0.5rem;

            .description-text {
              padding: 0 0.5rem;
            }
          }

          .access-types-body-wrapper {
            @include d-flex-v-center;
            flex-wrap: wrap;
            white-space: nowrap;
            padding: 0.5rem;
          }
        }
      }

      .checkbox-wrapper.Mui-checked {
        &.access-type-color-2 {
          .mdi {
            background-color: $c-success;
          }
        }

        &.access-type-color-1 {
          .mdi {
            background-color: $c-warning;
          }
        }

        &.access-type-color-3 {
          .mdi {
            background-color: $c-primary;
          }
        }

        &.access-type-color-6 {
          .mdi {
            background-color: $c-info;
          }
        }
      }
    }

    .roles-actions-wrapper {
      margin: 1rem 0 0.5rem;
      @include d-flex-center;
      width: 100%;

      .roles-actions {
        @include d-inline-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @include phones-max {
          flex-wrap: wrap;
          justify-content: center;
        }
      }

      width: 100%;
    }
  }

  .title-text {
    font-size: $fz-13px;
    font-weight: $fw-simi-bold;
  }

  .description-text {
    color: $c-gray-777;
  }

  .roles-title {
    .title-text {
      font-size: $fz-14px;
    }

    margin-bottom: 0.25rem;
  }
}

.a-box {
  display: inline-block;
  width: 240px;
  text-align: center;
  margin: 2rem;
}

.img-container {
  height: 230px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  background-image: linear-gradient(-20deg, #0C111D 0%, #7adeff 100%);
  transform: skew(360deg, 348deg);
  height: 213px;
  margin: -17px -52px -4px -54px;

  &.isClose {
    background-color: #cad4f740 !important;
  }
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  transform: skew(0deg, 13deg);
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 178px;
  width: 173px;
}

.text-container {
  box-shadow: 0px 0px 10px 0px #00000033;
  padding: 117px 8px 22px 14px;
  border-radius: 20px;
  background: #fff;
  margin: -149px 0px 0px 0px;
  line-height: 19px;
  font-size: 13px;
  background-repeat: no-repeat;
  transition: 1s all;
  background-size: 0% 100%;
  text-align: initial;

  .title-l {
    font-weight: 900;
  }
  .title-des {
    font-weight: 900;
    width: 120px;
    padding: 0px 5px 0px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #04bcff;
  font-size: 18px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-container:hover {
  background-color: rgba(128, 128, 128, 0.179);
  background-size: 100% 100%;
}

.CardReportsBuilder-wraper {
  justify-content: center;
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;

  .opation-1 {
    .MuiFab-sizeSmall {
      width: 35px;
      height: 38px;
      position: absolute;
      margin-left: 13.3rem;
      margin-top: -6rem;

      @include rtl {
        margin-right: 13.3rem;
      }
    }
  }

  .opation-2 {
    .MuiFab-sizeSmall {
      width: 35px;
      height: 38px;
      position: absolute;
      margin-left: 13.3rem;
      margin-top: -3rem;

      @include rtl {
        margin-right: 13.3rem;
      }
    }

    .MuiFab-secondary {
      color: #fff;
      background-color: #0d0508;
    }
  }

  .opation-3 {
    .MuiFab-secondary {
      color: #fff;
      background-color: #db504a;
    }

    .MuiFab-sizeSmall {
      width: 35px;
      height: 38px;
      position: absolute;
      margin-left: 13.3rem;
      margin-top: 1px;

      @include rtl {
        margin-right: 13.3rem;
      }
    }
  }
}

.ReportSettingsView-view-wrapers {
  .Requierd-Color::after {
    content: ' *';
    color: $c-red;
  }

  width: 100%;

  .main-view-wrapers {
    .sc-opation {
      .checkbox-groups-wrapper
        .form-control-label.MuiFormControlLabel-root
        .MuiFormControlLabel-label {
        font-size: 14px;
        width: 200px;
      }

      display: flex;
      width: -moz-fit-content;
      width: fit-content;
      justify-content: space-between;
      width: 100%;
    }
  }

  .check-box-wraper-repert {
    width: 100%;
    display: flex;
    padding-top: 2rem;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.TableReports-no-data {
  .img-div-container {
    display: flex;
    justify-content: center;
  }

  .Text-fileds {
    display: flex;
    justify-content: center;
    font-size: 23px;
    margin-bottom: 12px;
    font-weight: bolder;
    text-shadow: 1px -1px 20px #0e1adf;
  }

  display: flex;
  justify-content: center;

  .img-drag {
    height: 25rem;
    padding: 2rem;

    @include rtl {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}
.PaginationComponentx-component-wrapper
{

      margin-left: 10%;
      margin-right: 10%;
}
/////////////

.ReportRunView-Main {
  @media print {
    .navbar {
      display: none;
    }    .right {
      display: none;
    }    .middle {
      display: none;
    }
    .pagination-component-wrapper {
      display: none;
    }
    .aReport-Fab:after {
      content: none !important;
    }

    .Report-Fab[href]:after {
      content: none !important;
    }

    .Report-Fab {
      display: none;
    }

    .mdi:before {
      display: none !important;
    }

    .mdi {
      display: none !important;
      content: none !important;
    }
  }

  .right {
    width: 9rem;
    display: flex;
    justify-content: space-between;

    .MuiFab-root {
      color: #000000de;
      width: 39px;
      height: 41px;
      padding: 0;
    }
  }

  .navbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 61px;
    background-color: $c-white;
    box-shadow: 0px 1px 1px #dadada;
  }

  a {
    text-decoration: none;
  }

  .left {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .left .logo {
    margin: 14px 10px 8px 11px;
  }

  .search-box {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background: #f1f2f5;
    border: 0.0625em solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 30px;
  }

  .search-box input {
    font-size: 16px;
    border: 0;
    background: transparent;
    padding: 0px 6px 0 34px;
    width: 184px;
    height: 40px;
    outline: none;
  }

  .search-box svg {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 12px;
  }

  .middle {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .middle svg {
    width: auto;
    height: auto;
    padding: 3px;
    margin-right: 59px;
    transition: transform 0.3s;
  }

  .middle svg:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
  }

  .middle svg:active {
    transform: scale(0.9);
    transition: transform 0.3s;
  }

  .right {
    margin-right: 7px;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .right a {
    padding: 11px;
    background-color: #e5e6eb;
    border-radius: 30px;
    margin-right: 8px;
    height: auto;
    width: auto;
  }

  .right a:hover {
    -webkit-box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.93);
    box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.93);
    transition: box-shadow;
  }

  .right svg:active {
    transform: scale(0.9);
    transition: transform 0.3s;
  }

  .right svg {
    width: 16px;
    position: relative;
    top: 2px;
    right: -2px;
  }

  .messenger svg {
    right: -2px;
    top: 4px;
  }

  .notifications svg {
    right: -2px;
    top: 3px;
  }

  .dropdown-menu {
    width: 42px;
  }

  .dropdown-menu svg {
    top: 0px;
    right: 0px;
  }

  .notifications span {
    position: absolute;
    top: 12px;
    right: 65px;
    background: #dc565b;
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }

  @media print {
    .export-excel {
      display: none;
    }

    .aReport-Fab:after {
      content: none !important;
    }

    .Report-Fab[href]:after {
      content: none !important;
    }

    .Report-Fab {
      display: none;
    }

    .mdi:before {
      display: none !important;
    }

    .mdi {
      display: none !important;
      content: none !important;
    }
  }

  .ReportPdfView-PAGE {
    .Report-Fab {
      position: fixed;
      right: 5rem;
      z-index: 10;
      top: 1rem;
    }
  }

  .ReportPdfView-PAGE {
    .Report-Fab {
      position: fixed;
      right: 5rem;
      z-index: 10;
      top: 1rem;
    }

    @media print {
      .aReport-Fab:after {
        content: none !important;
      }

      .Report-Fab[href]:after {
        content: none !important;
      }

      .Report-Fab {
        display: none;
      }

      .mdi:before {
        display: none !important;
      }

      .mdi {
        display: none !important;
        content: none !important;
      }
    }
  }

  display: block;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  .export-excel {
    .counter-bb {
      display: flex;
      justify-content: center;
    }
  }

  .filter-section {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
    justify-items: center;
    position: relative;
    width: 100%;
  }

  .btns-icon.MuiButtonBase-root.theme-solid {
    border-radius: 30%;
  }

  .section-top {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .arrowImage {
    @include rtl {
      transform: rotateY(180deg);
    }
  }

  .ReportEmptyPage {
    font-size: 1rem;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: auto;
  }

  .btns.MuiButtonBase-root {
    border-radius: 5px;
    min-height: 7px;
    line-height: 100%;
    min-width: 85px;
    margin: 0 0.25rem;
    padding: 0.219rem 0.5rem;
    text-transform: initial;
    font-size: 10px;
    color: var(--c-primary);
    white-space: nowrap;
  }

  .excelPhoto {
    border: 1px solid #dedede;
    border-radius: 100%;
    padding: 0.5rem;
    background-color: white;

    .excelImage {
      min-width: 15px;
      height: 40px;
      width: 42px;
    }
  }
}

.isLoading-Reports {
  height: 33.4px;

  .Data-Tabel {
    font-size: 15px;
    font-weight: 900;
  }

  .loader-line {
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  .loader-line:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: rgb(37, 131, 253);
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
      left: -40%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }
}
 
.Skeleton-TableReportsView{

.MuiSkeleton-pulse {
  animation: MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}
.MuiSkeleton-text {
  height: auto;
  transform: scale(1, 1.3);
  margin-top: 0;
  border-radius: 3px;
  margin-bottom: 0;
  transform-origin: 0 60%;
}}

.report-builder-log-dialog{

  .filter-capsule{
    display: inline-block;
    color: var(--c-primary);
    font-weight: 600;
    border-radius: 30px;
    padding: 2px 5px;
    margin: 4px;
  }
  .filter-capsule .mdi:before{
    font-size: 15px;
    margin-right: 5px;
  }
  .process-filters{
    display: flex;
  }
}
.li-tree-container
{
  cursor: move;
}