.img-logo {
  background-image: $img-logo;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  width: 100px;
}
.Bayut {
  background-image: $Bayut-logo;
}
.Dubizzle {
  background-image: $Dubizzle-Logo;
}
.Houza {
  background-image: $Houza-Logo;
}
.Property {
  background-image: $property-finder;
}

