.i-bell-blue {
  background-image: $i-bell-blue;
}

.i-text-gray {
  background-image: $i-text-gray;
}

.i-home-blue {
  background-image: $i-home-blue;
}

.i-home-blue-dark {
  background-image: $i-home-blue-dark;
}

.i-home-gray {
  background-image: $i-home-gray;
}

.i-home-white {
  background-image: $i-home-white;
}

.i-contacts-blue {
  background-image: blue;
}

.i-contacts-blue-dark {
  background-image: dark;
}

.i-contacts-gray {
  background-image: gray;
}

.i-contacts-white {
  background-image: white;
}

.i-people-blue {
  background-image: $i-people-blue;
}

.i-people-blue-dark {
  background-image: $i-people-blue-dark;
}

.i-people-gray {
  background-image: $i-people-gray;
}

.i-people-white {
  background-image: $i-people-white;
}

.i-arrow-reverse-blue {
  background-image: $i-arrow-reverse-blue;
}

.i-arrow-reverse-blue-dark {
  background-image: $i-arrow-reverse-blue-dark;
}

.i-arrow-reverse-gray {
  background-image: $i-arrow-reverse-gray;
}

.i-arrow-reverse-white {
  background-image: $i-arrow-reverse-white;
}

.i-speed-monitor-blue {
  background-image: $i-speed-monitor-blue;
}

.i-speed-monitor-blue-dark {
  background-image: $i-speed-monitor-blue-dark;
}

.i-speed-monitor-gray {
  background-image: $i-speed-monitor-gray;
}

.i-speed-monitor-white {
  background-image: $i-speed-monitor-white;
}

.i-report-edit-blue {
  background-image: $i-report-edit-blue;
}

.i-report-edit-blue-dark {
  background-image: $i-report-edit-blue-dark;
}

.i-report-edit-gray {
  background-image: $i-report-edit-gray;
}

.i-report-edit-white {
  background-image: $i-report-edit-white;
}

.i-report-view-blue {
  background-image: $i-report-view-blue;
}

.i-report-view-blue-dark {
  background-image: $i-report-view-blue-dark;
}

.i-report-view-gray {
  background-image: $i-report-view-gray;
}

.i-report-view-white {
  background-image: $i-report-view-white;
}

.i-folder-blue {
  background-image: $i-folder-blue;
}

.i-folder-blue-dark {
  background-image: $i-folder-blue-dark;
}

.i-folder-gray {
  background-image: $i-folder-gray;
}

.i-folder-white {
  background-image: $i-folder-white;
}

.i-box-circle-blue {
  background-image: $i-box-circle-blue;
}

.i-box-circle-blue-dark {
  background-image: $i-box-circle-blue-dark;
}

.i-box-circle-gray {
  background-image: $i-box-circle-gray;
}

.i-box-circle-white {
  background-image: $i-box-circle-white;
}

.i-activities {
  background-image: $i-activities;
}

.i-cloud {
  background-image: $i-cloud;
}

.i-upload {
  background-image: $i-upload;
}

.i-shield {
  background-image: $i-shield;
}

.i-eiffel-tower {
  background-image: $i-eiffel-tower;
}

.i-calendar-blank {
  background-image: $i-calendar-blank;
}

.i-calendar {
  background-image: $i-calendar;
}

.i-oclock {
  background-image: $i-oclock;
}

.i-email {
  background-image: $i-email;
}
.i-email-black {
  background-image: $i-email-black;
}

.i-account {
  background-image: $i-account;
}
.i-box {
  background-image: $i-cardboard-boxblue;
}

.i-manged-black {
  background-image: $i-manged-black;
}
.i-manged-blue {
  background-image: $i-manged-blue;
}
.i-non-manged-black {
  background-image: $i-non-manged-black;
}
.i-non-manged-blue {
  background-image: $i-non-manged-blue;
}
.i-contact {
  background-image: $i-contact;
}
.i-property {
  background-image: $i-property;
}
.i-lead {
  background-image: $i-lead;
}
.i-unit {
  background-image: $i-unit;
}
.i-activity {
  background-image: $i-activity;
}
.i-task {
  background-image: $i-task;
}
.i-email-tag {
  background-image: $i-email-tag;
}
.i-sms-tag {
  background-image: $i-sms-tag;
}
.i-whatsapp-tag {
  background-image: $i-whatsapp-tag;
}

.i-number-sign {
  background-image: $i-number-sign;
}
.i-city {
  background-image: $i-city;
}
.i-users {
  background-image: $i-users;
}
.i-country {
  background-image: $i-country;
}
.i-managerCrown {
  background-image: $i-managerCrown;
}

.i-Flag_of_Russia {
  background-image: $i-Flag_of_Russia;
}

.i-Flag_of_theChina {
  background-image: $i-Flag_of_theChina;
}

.i-United_Arab {
  background-image: $i-United_Arab;
}

.i-_United_States {
  background-image: $i-_United_States;
}
