// @font-face {
//   font-family: Poppins;
//   font-display: swap;
//   src: url('../../../../fonts/Poppins-Regular.ttf');
// }
// @font-face {
//   font-family: TheSansArabic;
//   font-display: swap;
//   src: url('../../../../fonts/TheSansArabic-SemiBold.otf');
// }
$fz-default: 12px;
$fz-9px: 9px;
$fz-10px: 10px;
$fz-11px: 11px;
$fz-12px: 12px;
$fz-13px: 13px;
$fz-14px: 14px;
$fz-15px: 15px;
$fz-16px: 16px;
$fz-18px: 18px;
$fz-19px: 19px;
$fz-20px: 20px;
$fz-21px: 21px;
$fz-22px: 22px;
$fz-24px: 24px;
$fz-25px: 25px;
$fz-28px: 28px;
$fz-30px: 30px;
$fz-36px: 36px;
$fz-38px: 38px;
$fz-40px: 40px;
$fz-45px: 45px;
$fz-50px: 50px;
$fz-75px: 75px;
$fz-table-header: 12px;
$fz-table-body: 11px;
$fz-table-footer: 12px;
$ff-default: Poppins, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
  monospace;
$ff-defaultAR: TheSansArabic, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;
$fw-default: 400;
$fw-normal: 400;
$fw-medium: 500;
$fw-simi-bold: 600;
$fw-bold: 700;
$fw-bolder: bolder;
